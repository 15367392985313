@import '../../../tenantConfig/styles/base.scss';

.HorizontalNav {
    display: flex;
    justify-content: space-between;
    padding: inherit;

    &__menuItem {
        list-style: none;
        width: 100%;
        justify-self: center;
        display: flex;
        flex-direction: column;
    }

    &__link,
    &__link_selected {
        display: flex;
        align-items: center;
        height: 100%;
        padding: 1.3rem 3rem 1.3rem 3rem;
        border-radius: 0.25rem;
        text-decoration: none;
        justify-content: center;
        font-family: $font-family;
        font-size: 1.3em;
        margin: 0;
        gap: 0.5rem;
        will-change: transform;
        transition: transform 250ms, color 400ms;
    }

    &__link_selected {
        color: $secondary;
    }

    &__link:hover,
    &__link:focus {
        transform: translateY(0.1rem);
        color: $secondary;
    }

    &__icon {
        font-size: 1em;
    }
}
