@import '../../../tenantConfig/styles/base.scss';

.AccountPopover {
    &__container {
        background-color: $white;
        width: 100%;
        border-radius: 0.25rem;
        display: grid;
        grid-template-rows: auto;
        box-shadow: $default-box-shadow;
        font-size: 1rem;
        padding: 0.25rem;
    }

    &__trigger,
    &__close {
        background-color: transparent;
        border: none;
    }

    &__close {
        justify-self: right;
        align-self: flex-start;
    }

    &__closeIcon {
        font-size: 1.2em;
        max-height: fit-content;
        padding: 0.2rem;
        border-radius: 100%;
    }

    &__actionContainer {
        display: grid;
        padding: 1rem;
        text-align: left;
    }

    &__loginButton {
        background-color: $dark;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        padding: 0.4rem 4rem;
        border-radius: 0.25rem;
        margin: 0 0 1rem 0;
        border: none;
        font-family: $font-family;
        font-size: 1em;
        height: 2rem;
        width: 100%;
    }

    &__loginButton:disabled {
        background-color: $disabled;
        color: $disabled-dark;
    }
    &__accountButtonContainer {
        display: flex;
        gap: 0.7rem;
    }

    &__accountButton {
        font-family: $font-family;
        color: $secondary;
        font-size: 0.95em;
        padding: 0.2rem 0;
        text-decoration: none;
        display: flex;
        gap: 0.7rem;
        background-color: transparent;
        border: none;
        text-transform: capitalize;
    }

    &__accountButton:hover {
        text-decoration: underline;
        color: $primary;
    }

    &__emailContainer {
        display: flex;
        gap: 0.5rem;
        padding: 0 0 0.25rem 0;
        align-items: center;
        color: $dark;
        text-transform: lowercase;
    }

    &__emailIcon {
        font-size: 1.2em;
    }

    &__email {
        margin: 0;
        font-family: $font-family;
        color: $secondary;
    }

    &__accountTrigger {
        color: $secondary;
        font-family: $font-family;
        text-align: center;
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    &__closeIcon:hover {
        background-color: $info-light;
    }

    &__accountIcon {
        padding: 0.5rem;
    }
}
