@import './base.scss';

.CustomSharedAccount {
    display: flex;

    justify-content: center;

    margin: 5.2vw;

    &__card {
        font-family: $font-family;
        color: $dark;
        border-radius: 0.2rem;
        width: 50rem;

        max-height: 40rem;
    }

    .SharedAccount__linkContainer {
        display: flex;
        justify-content: flex-start;
        font-size: 1em;
    }

    .SharedAccount__linkWrapper {
        margin: 0.5rem 0;
    }

    .SharedAccount__link {
        margin: 0;
        color: $primary;
    }
    .SharedAccount__link:hover {
        text-decoration: none;
    }

    @media screen and (max-width: $mobile) {
        margin: 0;
        padding: 1rem 0;
        min-height: calc(100vh - 18.6rem);

        &__card {
            margin-top: 0rem;
        }
    }

    @media screen and (max-width: $tablet) and (min-width: calc($mobile + 1px)) {
        margin: 7vw 2rem;
        align-items: normal;
        min-height: calc(100vh - 21.6rem);

        &__card {
            margin-top: 0rem;
        }
    }
    @media screen and (max-width: calc($laptop)) {
        &__card_conf {
            min-height: calc(100vh - 20.5rem);
        }
    }

    @media screen and (min-width: calc($laptop + 256px)) {
        &__card_conf {
            min-height: calc(100vh - 20.5rem);
        }
    }
    @media screen and (min-width: $desktop) {
        height: calc(100vh - 6.375rem);

        margin: 0 auto;

        &__card {
            margin-top: 10vw;
        }

        &__card_Account {
            margin-top: 8vw !important;
        }
    }
}

.PasswordRequirements {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-bottom: 1rem;

    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
    }

    &__icon_error,
    &__icon_success,
    &__icon_inactive {
        font-size: 1.1em;
    }

    &__icon_error,
    &__message_error {
        color: $error;
    }

    &__icon_success,
    &__message_success {
        color: $success;
    }

    &__icon_inactive,
    &__message_inactive {
        color: $muted;
    }

    &__message_error,
    &__message_success,
    &__message_inactive {
        margin: 0;
        font-family: $font-family;
        font-size: 0.9em;
    }
}

.CustomSignUpCard,
.CustomCustomForgotPassword,
.CustomResetPassword,
.CustomConfirmCard {
    &__inputContainer {
        padding-top: 0rem;
    }
}

.CustomSignUpCard,
.CustomLoginCard,
.CustomForgotPassword,
.CustomResetPassword,
.CustomConfirmCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $white;
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
    border: 2px;
    box-shadow: $account-box-shadow;

    &__input {
        width: 100%;
    }

    &__signUpLinks {
        display: flex;
        justify-content: flex-start;
    }

    &__inputContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 0.2rem;

        input {
            margin-top: 0.5rem;
        }
    }

    &__passwordContainer {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
    }

    @media screen and (max-width: $mobile) {
        width: auto;
        margin: 1rem;
        padding: 1rem;
    }
}
.CustomSignUpCard,
.CustomResetPassword,
.CustomForgotPassword,
.ConfirmCard,
.CustomLoginCard {
    &__header {
        margin: 0;
        font-family: $font-family-bold;
        font-size: 1.375em;
        text-transform: uppercase;
        color: $primary;

        font-weight: 700;
        line-height: 1.2;
        @media screen and (max-width: $mobile) {
            font-size: calc(1.125rem + 0.25 * ((100vw - 18.75rem) / 56.25));
        }
        @media screen and (max-width: $tablet) and (min-width: calc($mobile + 1px)) {
            font-size: calc(1.125rem + 0.25 * ((100vw - 18.75rem) / 56.25));
        }
    }

    &__passwordReset {
        margin: 0;

        display: flex;
        justify-content: flex-end;
        padding-top: 0.5rem;
        font-size: 1em;
        color: $primary;
    }
    &__resendConfirmCodeButtonContainer {
        display: flex;
        justify-content: flex-end;
    }
    &__passwordReset:hover,
    &__resendConfirmCodeButton:hover {
        text-decoration: none;
    }
    &__resendConfirmCodeButton {
        padding: 0;
        padding-top: 0.5rem;
        font-family: $font-family;
        display: flex;
        justify-content: flex-end;
        background: none;
        border: none;

        text-decoration: underline;
        font-size: 1em;
        color: $primary;
        justify-content: flex-end;
    }

    &__submitButton {
        display: flex;
        border: none;

        font-family: $font-family;
        padding: 0.8rem 1.4rem;
        background-color: $primary;
        color: $white;
        line-height: 1.2;
        font-size: 1.15em;
        font-weight: 600;
        text-transform: uppercase;
        justify-content: center;
        margin: 1rem 0 0 0;
    }

    &__submitButton:hover {
        background-color: $secondary-dark;
        color: $white;
    }
}
.CustomForgotPassword {
    &__submitButton {
        margin-top: 1rem;
    }
}
.CustomConfirmCard {
    &__resendConfirmCodeButton {
        padding: 0;
        font-family: $font-family;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        background: none;
        border: none;
        color: $primary-dark;
        font-size: 1em;

        text-decoration: underline;
    }
    &__resendConfirmCodeButton:hover {
        text-decoration: none;
    }
}
