@import '../../../tenantConfig/styles/base.scss';

.Dialog {
    background-color: $white;
    position: fixed;
    opacity: 1;
    font-family: $font-family;
    color: $dark;
    padding: 2rem;
    border-radius: 0.2rem;
    box-shadow: $default-box-shadow;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &__closeTrigger {
        display: flex;
        justify-content: flex-end;
        width: 100%;
    }

    &__headerContainer {
        display: flex;
        align-items: flex-start;
        white-space: nowrap;
        justify-content: space-between;
        @media screen and (max-width: $mobile) {
            padding-top: 10%;
        }
    }

    &__trigger,
    &__closeTrigger {
        background-color: transparent;
        border: none;
        outline: none;
    }

    &__header {
        margin: 0;
    }
}
