$primary: #d01b35;
$primary-light: #b8d1e6;
$primary-dark: #0075ff;
$primary-darkest: #114e80;
$secondary: #807f7f;

$secondary-light: #a6a5a5;
$secondary-dark: #343434;
$secondary-darkest: #414141;

$light: #dedddb;
$info-light: #efeeed;
$disabled: #6c757d71;
$disabled-dark: #0010217a;
$dark: #001021;
$default-dark: #000000;
$accent: #cf962a;
$dark-accent: #ad7e27;

$white: #fff;
$muted: #6c757d;

$error: #c93214;
$error-light: #f9c2b7;
$error-dark: #7f2513;

$success: #2a7b3b;
$success-light: #b2d5b6;
$success-dark: #1f4426;
$accordion-before: #4e0c35;

$default-box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
$heavy-box-shadow: 0 3px 10px rgb(0 0 0 / 0.4);
$account-box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);

$default-background-allpage: #f5f5f5;
$default-background-completion: linear-gradient(rgba(245, 245, 245, 0.65), rgba(245, 245, 245, 0.65));
$default-background: linear-gradient(rgba(245, 245, 245, 0.85), rgba(245, 245, 245, 0.85));
$default-bnner: linear-gradient(rgba(208, 27, 53, 0.85), rgba(208, 27, 53, 0.85));
$default-bnner-clip-path: polygon(50% 15%, 100% 0, 100% 86%, 50% 100%, 0 86%, 0 0);

@font-face {
    font-family: 'optima';
    src: url(../public/fonts/optima-webfont.woff);
}

@font-face {
    font-family: 'helvetica';
    src: url(../public/fonts/helvneue-regular.woff);
}

@font-face {
    font-family: 'nexa';
    src: url(../public/fonts/nexa-black.woff);
}

$font-family: 'helvetica', Helvetica, Arial, sans-serif;
$font-family-bold: 'optima', Segoe, 'Segoe UI', Candara, Calibri, Arial, sans-serif;
$font-family-regular: 'nexa';

$account-bg-image: '../public/background.jpg';
$active-nav-bg-image: '../public/arrow-grey.svg';

//Device width for responsive
$mobile: 675px;
$tablet: 900px;
$laptop: 1024px;
$desktop: 1440px;
$macbook-pro: 0px; // DO NOT USE, should not have been introduced in the first place
