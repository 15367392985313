@import '../../../tenantConfig/styles/base.scss';

.LoadingCircles {
    &__primary {
        background-color: $primary;
    }

    &__primary-dark {
        background-color: $primary-dark;
    }

    &__secondary {
        background-color: $secondary;
    }

    &__disabled {
        background-color: $disabled;
    }

    &__dark {
        background-color: $dark;
    }

    &__white {
        background-color: $white;
    }
}
