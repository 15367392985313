@import '../../../tenantConfig/styles/base.scss';

.LoadingSpinner {
    height: 100vh;
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    top: 0;
    z-index: 2147483647; // Highest possible z-index. overlay everything

    &__spinner {
        width: 10rem;
        height: 10rem;
    }

    &__path {
        fill: none;
        stroke-width: 4px;
        stroke-linecap: round;
    }
}
