@import '../../../tenantConfig/styles/base.scss';

.ButtonWithIcon {
    display: flex;
    border: none;
    box-shadow: $default-box-shadow;
    background-color: $dark;
    font-family: $font-family;
    padding: 0.8rem 1.4rem;
    color: $light;
    border-radius: 0.25rem;

    &__loadingContainer {
        display: flex;
        justify-content: space-around;
    }
}

.ButtonWithIcon:hover,
.ButtonWithIcon:focus {
    transform: translateY(0.1rem);
    background-color: $dark;
}

.ButtonWithIcon:disabled {
    background-color: $disabled;
    color: $disabled-dark;
}
