@import '../../../tenantConfig/styles/base.scss';

.ValidationError {
    color: $error;
    font-family: $font-family;
    font-size: 0.9em;
    padding: 0.25rem 0;

    &__text {
        margin: 0;
        padding: 0.25rem 0 0 0;
    }
}
