@import '../../../tenantConfig/styles/base.scss';

.BasicToast {
    border-radius: 0.25rem;
    padding: 0 0.5rem 0.5rem 0.5rem;
    display: grid;
    grid-template-areas:
        'successIcon title close'
        '. description description';
    grid-template-columns: 2rem 1fr 1.5rem;
    align-content: baseline;
}

.BasicToast_success {
    @extend .BasicToast;
    background-color: $white;
    color: $dark;
    outline: 1px solid $info-light;
    box-shadow: $default-box-shadow;
}

.BasicToast_error {
    @extend .BasicToast;
    background-color: $error-light;
    color: $error-dark;
    box-shadow: $default-box-shadow;
}

.BasicToast {
    &__viewport {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 100vw;
        margin: 0;
        list-style: none;
        z-index: 2000000;
        outline: none;
    }

    &__viewport_error {
        @extend .BasicToast__viewport;
        padding: 0;
        width: 100%;
    }

    &__viewport_success {
        @extend .BasicToast__viewport;
        position: fixed;
        bottom: 0;
        right: 0;
        width: 390px;
        padding: 2rem;
    }

    &__close_error,
    &__close_success {
        background-color: transparent;
        outline: none;
        border: none;
        width: 1.5rem;
        height: 1.5rem;
        display: flex;
        place-items: center;
        grid-area: close;
    }

    &__successIcon_error,
    &__successIcon_success {
        grid-area: successIcon;
        padding-top: 0.5rem;
    }

    &__description_error,
    &__description_success {
        grid-area: description;
        font-family: $font-family;
    }

    &__closeIcon {
        outline: none;
        font-size: 1.2em;
        color: $secondary;
        border-radius: 100%;
        padding: 0.25rem;
    }

    &__closeIcon_success {
        @extend .BasicToast__closeIcon;
        color: $dark;
    }

    &__closeIcon_success:hover {
        @extend .BasicToast__closeIcon;
        background-color: rgba(0, 0, 0, 0.2);
    }

    &__title_error,
    &__title_success {
        font-family: $font-family-regular;
        padding-top: 0.5rem;
        grid-area: title;
    }

    &__successIcon_success {
        color: $success;
    }

    &__header_error,
    &__header_success {
        display: flex;
        justify-content: space-between;
    }
}

//some CSS
@media screen and (max-width: $mobile) {
    .BasicToast {
        &__viewport_success {
            @extend .BasicToast__viewport;
            position: fixed;
            top: 0;
            right: 0;
            width: 100vw;
            padding: 0rem;
        }
    }
}
