@import '../../../tenantConfig/styles/base.scss';

.ToggleVisibility {
    &__iconButton {
        padding: 0.3rem;
        border-radius: 2rem;
        display: flex;
        justify-content: center;
    }

    &__iconButton:hover {
        background-color: $light;
    }

    &__inlineInput {
        display: flex;
        line-height: 1.5;
        align-items: center;
        white-space: nowrap;
        gap: 0.5rem;
    }
}
