@import './base.scss';

.Footer {
    &__container {
        padding: 1rem;
        background: $primary;
    }
    &__container_Account {
        padding: 1rem;
        background: $primary;
    }
    &__SchoolName {
        font-weight: bold;
        font-family: $font-family-bold;
        font-size: 1.125em;
    }
    &__wrapper {
        flex-direction: row;
        justify-content: space-between;
        display: flex;
        align-items: center;
        max-width: 75rem;
        margin: 0 auto;
        color: $white;
    }

    &__helplinks {
        display: flex;
    }

    &__completion {
        margin-top: 0rem;
        background-color: $white;
    }

    &__content {
        grid-area: 'footerContent';
        margin: 0.5rem 2rem;
        line-height: 1.2;
        display: flex;
        gap: 1rem;
    }

    &__links {
        display: flex;
    }
    &__triger_link {
        color: $white;

        text-decoration: underline;
    }
    &__triger_link:hover {
        color: $white;
        text-decoration: none;
    }
    &__separator {
        background-color: $white;
    }

    &__school_name {
        font-size: 0.875em;
        margin: 0;
        font-weight: 600;
    }
    &__school_address {
        font-size: 0.875em;
        margin: 0 0 1rem 0;
    }

    @media screen and (max-width: $mobile) {
        &__wrapper {
            flex-direction: column;
            justify-content: center;
        }
        &__address {
            text-align: center;
            margin: 0 0 1rem;
        }
    }

    @media screen and (min-width: $desktop) {
        &__container_Account {
            position: fixed;
            bottom: 0;
            width: 100%;
        }
    }
}
