@import '../../../tenantConfig/styles/base.scss';

.TextArea {
    &__input {
        outline: none;
        width: 100%;
        color: $dark;
        font-family: $font-family;
        font-size: 1em;
        line-height: 1.5;
        padding: 0.25rem 0.5rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        border: 1px solid $light;
        transition: border 0.5s ease;
        border-radius: 0.2rem;
    }

    &__input:focus,
    &__input:hover {
        outline: none;
        border: 1px solid $primary;
    }

    &__description {
        font-family: $font-family;
        color: $muted;
        font-size: 1em;
    }
}
