@import './base.scss';
@import './Modal.scss';
@import './Account.scss';
@import './Footer.scss';
@import './Form.scss';
@import './Completion.scss';

html * {
    font-family: $font-family;
    line-height: 1.4;
    word-wrap: break-word;
    font-size: 1rem;
    letter-spacing: 0.02em;
    word-spacing: -0.02em;
}

body {
    counter-reset: navcounter;
}
.AccountPopover {
    &__container {
        margin-top: -1.5rem;
    }
    &__CustomAccountTrigger {
        height: 0rem;
        transition: 0.2s ease-in-out;
        font-size: 1rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: auto;
        padding: 0.85em 1.25em;

        text-decoration: none;
        color: $primary;
        font-family: $font-family-regular;
        text-transform: uppercase;
        border: 0;
        outline: 0;
        background: $white;
        line-height: normal;
        position: relative;
        text-align: center;
        font-size: 0.875rem;
        padding: 0.5rem;

        div {
            font-family: $font-family-regular;
        }
    }

    &__CustomAccountTrigger:hover {
        background-color: $white;
        color: $secondary-dark;
    }

    &__CustomLoginButton {
        background-color: $primary;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
        padding: 0.4rem 4rem;
        border-radius: 0.25rem;
        margin: 0 0 1rem 0;
        border: none;
        font-size: 1em;
        height: 2rem;
        width: 100%;
    }

    &__CustomLoginButton:hover {
        background-color: $secondary-dark;
        color: $white;
    }
}

.School {
    &__body {
        background-color: $default-background-allpage;
    }
    &__main {
        background-image: $default-background, url($account-bg-image);
        background-repeat: no-repeat;
        background-size: cover;
        flex-grow: 1;
    }
    &__AccountHeader {
        background-color: $white;
        padding: 1rem;

        @media screen and (min-width: $desktop) {
            position: fixed;
            width: 100%;
            top: 0;
        }
    }
    &__header {
        background-color: $white;
        padding: 1rem 1rem 0rem 1rem;
    }
    &__header_account {
        z-index: 1;
        display: flex;
        flex-direction: column;
    }
    &__header_accountBtn {
        display: flex;
        flex-direction: row-reverse;
        margin-right: 11.5rem;
        z-index: 2;
        position: relative;
        max-width: 53rem;
        margin: 0 auto;
    }
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 75rem;
        margin: 0 auto;
    }
    &__logo {
        img {
            height: 3.6625rem;
            max-width: 17rem;
            display: block;
        }
    }
    &__title {
        font-size: 1.5625em;
        color: $primary;
        font-family: $font-family-bold;
        text-transform: uppercase;
        font-weight: bold;
        margin: 0;
    }

    &__page_container {
        flex-grow: 1;
    }
    &__banner_main {
        background-image: $default-bnner, url($account-bg-image);
        clip-path: $default-bnner-clip-path;
        background-position: 0% 50%;
        background-size: cover;
        text-align: center;
        margin-top: 0.8rem;
    }
    &__banner_container {
        padding: 6% 1rem 3%;
        margin-top: 1rem;
        max-width: 50rem;
        margin: 0 auto;
        color: $white;
        font-family: $font-family;

        h3 {
            margin: 0 0 1rem 0;
            line-height: 1.2;
            font-family: $font-family-bold;
            font-weight: lighter;
            font-size: calc(1.4rem + 0.6 * ((100vw - 18.75rem) / 56.25));
        }
        p {
            margin: 1rem 0;
            font-style: normal;
        }
    }

    &__banner_login {
        font-weight: bold;
        text-decoration: underline;
        transition: 0.2s ease-in-out;
        border-bottom: 1px solid $primary;
        color: $white;
    }
    &__banner_login:hover {
        text-decoration: none;
    }
    &__banner_container_completion {
        padding: 6% 1rem 3%;
        margin-top: 1rem;
        max-width: 56.25rem;
        margin: 0 auto;
        color: $white;
        font-family: $font-family;
    }
    &__banner_container_header {
        display: inline-block;
        margin-bottom: 2rem;
        background-color: rgba(255, 255, 255, 0.9);
        padding: 0.5rem;
        color: $primary;
        font-family: $font-family-bold;
        font-size: 1.375em;
    }
    &__banner_container_message {
        color: $white;
        font-size: 2em;
        font-family: $font-family-bold;
        font-weight: lighter;
    }

    &__page_wrapper {
        max-width: 62.5rem;
        margin: 0 auto;
        padding: 1rem;
    }
    &__form_main_final {
        padding: 2rem;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
        background-color: $secondary-darkest;
        margin-bottom: 2rem;
        color: $white;
    }
    &__form_main {
        padding: 2rem;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
        background-color: $white;
        margin-bottom: 2rem;
    }
    &__form_section {
        padding-left: 1rem;
        p {
            color: $secondary-dark;
        }
    }
    &__form_formHeader_message {
        font-family: $font-family-bold;
        color: $primary;
    }
    &__form_finalHeader {
        color: $white;
        font-size: 2rem;
        font-family: $font-family-bold;
    }
    &__form_finalPara {
        color: $white;
        font-size: 1.375em;
        margin: 1rem 0;
        font-family: $font-family-bold;
    }
    &__form_formHeader {
        font-size: 2rem;
        text-transform: uppercase;
        margin: 0 0 1rem 0;
        line-height: 1.2;
        font-family: $font-family-bold;
        color: $primary;
        font-weight: lighter;

        h3 {
            color: $primary;
        }
    }
    &__form_formHeader_completion {
        font-size: 1.5625rem;

        margin: 0 0 1rem 0;
        line-height: 1.2;
        font-family: $font-family-bold;
        color: $primary;
        font-weight: lighter;

        h3 {
            color: $primary;
        }
    }
    &__form_formHeader_completion::before {
        content: '▸';
        display: inline-block;
        color: $primary;
        padding-right: 0.5rem;
        margin-left: -1.25rem;
    }
    &__form_subHeader {
        margin: 0.75rem 0 1.5rem;
        line-height: 1.2;
        font-family: $font-family-bold;
        color: $primary;
        font-weight: lighter;
        font-size: 1.5625rem;
    }
    &__form_question_message {
        display: flex;
        margin-left: auto;
        padding-left: 37.333%;
        margin-top: 0;
        font-size: 0.875em;
        font-style: italic;
        color: $secondary-dark;
    }
}

.CustomRadio {
    &__container {
        display: flex;
        gap: 1rem;
        align-items: center;
    }
}
.FormAutoComplete__control:focus,
.FormAutoComplete__control:hover {
    border: 1px solid $primary !important;
}
.FormAutoComplete__control {
    padding: 0.65rem 0.75rem !important;
}

.outlined__input {
    padding: 0.65rem 0.75rem !important;
    border-radius: 0 !important;
}
.Custom {
    &__input,
    &__input_checked {
        outline: none;
        border: 1px solid $secondary-light;

        max-height: 18px;
        max-width: 18px;
        min-width: 18px;
        min-height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        transition: 400ms 100ms ease-out;
    }
    &__input {
        background-color: transparent;
    }

    &__input_checked:focus,
    &__input_checked:hover {
        border: 2px solid $primary-dark;
    }
    &__input_checked {
        background-color: $primary-dark;
    }
    &__outlined_input:focus,
    &__outlined_input:hover {
        border: 1px solid $primary;
    }
    &__outlined_input {
        outline: none;
        width: 100%;
        color: $secondary-dark;
        font-size: 1em;
        font-family: $font-family;
        padding: 0.65rem 0.75rem;
        line-height: 1.5;
        box-sizing: border-box;
        border: 1px solid $secondary-light;
        transition: border 0.5s ease;
        border-radius: 0;
    }
    &__AutoComplete_input {
        padding: 0.65rem 0.75rem;
    }
}
.CustomHorizontalNav {
    margin: 1rem 0 2rem;
    display: flex;
    padding: 0;
    justify-content: space-between;

    li {
        display: flex;
        width: 30%;
        counter-increment: navCounter;
        a::before {
            margin-right: 0.5rem;
            content: counter(navCounter);
            color: $white;
            font-weight: bold;
            font-size: 1.25rem;
        }
    }

    &__link {
        text-decoration: none;
        transition: 0.2s ease-in-out;
        background-image: url($active-nav-bg-image);
        clip-path: polygon(0 0, 93% 0, 100% 50%, 93% 100%, 0 100%, 7% 50%);
        background: $primary;
        padding: 1.5rem 1rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 0;
        width: auto;
        color: $white;

        font-weight: bold;
    }
    &__link_edu {
        text-decoration: none;
        transition: 0.2s ease-in-out;
        background-image: url($active-nav-bg-image);
        clip-path: polygon(0 0, 93% 0, 100% 50%, 93% 100%, 0 100%, 7% 50%);
        background: $secondary;
        padding: 1.5rem 1rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 0;
        width: auto;
        color: $white;

        font-weight: bold;
    }
    &__link_selected {
        text-decoration: none;
        transition: 0.2s ease-in-out;
        background-image: url($active-nav-bg-image);
        clip-path: polygon(0 0, 93% 0, 100% 50%, 93% 100%, 0 100%, 7% 50%);
        background: $secondary-darkest;
        padding: 1.5rem 1rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 0;
        width: auto;
        color: $white;

        font-weight: bold;
    }

    &__link_selected:focus,
    &__link_selected:hover {
        background: $secondary-dark;
        outline: none;
    }

    &__link_edu:focus,
    &__link_edu:hover,
    &__link:focus,
    &__link:hover {
        background: $secondary-dark;
        outline: none;
    }
}
.CustomMaskedPhoneNumber {
    display: grid;
    grid-template-columns: 3.5fr 6.5fr;
    column-gap: 2rem;
    grid-template-areas:
        'questionAnswer questionAnswer'
        '. optIn';
    font-family: $font-family;
    color: $secondary;
    padding: 1rem 0;
    align-content: center;

    &__formGroup {
        display: grid;
        grid-area: questionAnswer;
        column-gap: 2rem;
        grid-template-columns: 3.5fr 6.5fr;
        grid-template-areas: 'label input';
    }

    &__label {
        grid-area: label;
        align-self: baseline;
        color: $secondary-dark;
    }

    &__input {
        grid-area: input;
    }

    &__optIn {
        grid-area: optIn;
    }
}

.FormRadio__label,
.SingleCheck__label,
.FormRadio__radioLabel {
    color: $secondary-dark !important;
}
.CustomSelectGroup {
    align-items: center;
    gap: 1rem;
    font-family: $font-family;
    color: $secondary-dark;
    &__single {
        display: grid;
        grid-gap: 2rem;
        grid-template-columns: 3fr 5.5fr;
        justify-content: flex-start;
        padding: 1rem 0;
    }

    &__monthYear {
        display: grid;
        grid-template-columns: 3fr 5.5fr;
        grid-gap: 2rem;
        justify-content: start;
        padding: 1rem 0;
    }

    &__doubleAnswers {
        display: grid;
        grid-gap: 0.5rem;
        grid-template-columns: 3fr 1.5fr;
    }
    .FormRadio__label,
    .SingleCheck__label,
    .FormRadio__radioLabel {
        font-size: 0.938em;
        font-weight: 400;
        color: $secondary-dark !important;
    }
}
.CustomSingleCheck {
    &__check,
    &__labelContainer {
        margin-top: 0.4rem;
    }
}
.Separator {
    background-color: $info-light;
    margin: 0.5rem 0;
}
.FormSeparator {
    border-bottom: 2px solid $primary;
}
.CompletionSeparator {
    border-bottom: 2px solid $primary;
    margin-bottom: 1rem;
}
.CustomFormSeparator {
    border-bottom: 1px solid $info-light;
}
.CustomFormSeparatorTop {
    border-top: 1px solid $info-light;
}

.CustomBottom {
    &__container {
        margin-bottom: 2rem;
    }
    &__button {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
    }
    &__button_education {
        display: flex;
        justify-content: flex-end;

        flex-wrap: wrap;
        flex-direction: row;
    }
    &__button_education_back {
        order: initial;
        margin-right: auto;
        width: auto;
    }
    &__button_education_save {
        order: initial;

        width: auto;
    }
    &__button_education_saveContinue {
        order: initial;

        width: auto;
    }
    &__button_save {
        transition: 0.2s ease-in-out;
        font-size: 1rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: auto;
        padding: 0.85em 1.25em;
        text-decoration: none;
        color: $white;
        font-family: $font-family-regular;
        text-transform: uppercase;
        border: 0;
        outline: 0;
        background: $secondary-light;
        line-height: normal;
        position: relative;
        text-align: center;
        margin: 0 1.5rem 0 0;
    }

    &__button_back:hover,
    &__button_back:focus,
    &__button_save:hover,
    &__button_save:focus {
        background-color: $secondary-dark;
        border: 0;
        color: $white;
    }
    &__button_saveContinue {
        margin: 0;
        display: inline-flex;
        width: auto;
        clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0 100%, 0 0);
        transition: 0.2s ease-in-out;
        font-size: 1rem;
        justify-content: center;
        align-items: center;
        padding: 0.85em 1.25em;
        text-decoration: none;
        color: $white;
        font-family: $font-family-regular;
        text-transform: uppercase;
        border: 0;
        outline: 0;
        background: $primary;
        line-height: normal;
        position: relative;
        text-align: center;
    }

    &__button_saveContinue:hover,
    &__button_saveContinue:focus {
        background-color: $secondary-dark;
        border: 0;
        color: $white;
    }

    &__button_back {
        display: inline-flex;
        width: auto;
        clip-path: polygon(5% 0, 0 50%, 5% 100%, 100% 100%, 100% 0);
        justify-content: center;
        align-items: center;

        padding: 0.85em 1.25em;
        cursor: pointer;
        text-decoration: none;
        color: $white;
        font-family: $font-family-regular;
        text-transform: uppercase;
        transition: 0.2s ease-in-out;
        font-size: 1rem;
        border: 0;
        outline: 0;
        background: $secondary-light;
        line-height: normal;
        position: relative;
        text-align: center;
        margin: 0 0 1.5rem 0;
    }

    &__button_back::before,
    &__button_back::after {
        transition: 0.2s ease-in-out;
        position: absolute;
        top: 0;
        bottom: 0;
        content: '';
        background-repeat: no-repeat;
        display: block;
        height: auto;
        width: 1.5rem;
    }
}

@media screen and (max-width: $mobile) {
    .CustomRadio {
        &__container {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    .School {
        &__container {
            flex-direction: column;
        }
        &__header_account {
            display: flex;
            flex-direction: column;
        }
        &__header_accountBtn {
            margin-top: 0.5rem;
            margin-right: 0;
            align-items: center;
            justify-content: center;
        }
        &__logo {
            margin-bottom: 1rem;
        }
        &__title {
            font-size: calc(0.875rem + 0.6875 * ((100vw - 18.75rem) / 56.25));
        }

        &__banner_main {
            margin-top: 0rem;
        }
        &__banner_container {
            padding: calc(16% + 2rem) 1rem 9%;
        }
        &__banner_container_completion {
            padding: calc(16% + 1rem) 1rem 15%;
        }
        &__page_wrapper {
            padding: 0.5rem;
        }
        &__form_main {
            padding: 1rem;
        }
        &__form_question_message {
            padding-left: 0;
        }
        &__form_formHeader,
        &__form_finalHeader,
        &__form_finalPara {
            font-size: calc(1.4rem + 0.6 * ((100vw - 18.75rem) / 56.25));
        }
        &__banner_container_header {
            margin: 2rem 0;
            font-size: calc(1.125rem + 0.25 * ((100vw - 18.75rem) / 56.25));
        }
        &__banner_container_message {
            font-size: calc(1.4rem + 0.6 * ((100vw - 18.75rem) / 56.25));
            font-weight: lighter;
        }
        &__form_formHeader_completion {
            font-size: calc(1.35rem + 0.2125 * ((100vw - 18.75rem) / 56.25));
        }
    }

    .CustomHorizontalNav {
        margin: 0rem 0 1rem;

        &__link,
        &__link_selected {
            width: auto;
        }
        a {
            clip-path: polygon(0 0, 85% 0, 100% 50%, 85% 100%, 0 100%, 15% 50%);
            div {
                display: none;
            }
        }
        li {
            a::before {
                margin-right: 0;
            }
        }
    }

    .CustomMaskedPhoneNumber {
        display: block;

        &__formGroup {
            display: block;
        }
    }
    .CustomSelectGroup {
        &__single {
            display: block;
        }

        &__monthYear {
            display: block;
        }
        &__doubleAnswers {
            display: grid;
            grid-gap: 0.5rem;
            grid-template-columns: 3fr 1.5fr;
        }
    }
    .FormRadio__label,
    .SingleCheck__label,
    .FormRadio__radioLabel {
        font-size: 0.938em;
        font-weight: 400;
    }

    .CustomBottom {
        &__button {
            display: flex;
            flex-direction: column-reverse;
            align-items: inherit;
            width: 100%;
        }
        &__button_back,
        &__button_save {
            margin: 1rem 0;
            width: 100%;
        }
        &__button_education {
            justify-content: space-between;
        }
        &__button_education_back {
            order: 3;
            width: 49%;
        }
        &__button_education_save {
            order: 4;
            width: 49%;
        }
        &__button_education_saveContinue {
            order: 2;
            width: 100%;
        }
        &__button_saveContinue {
            width: 100%;
        }
        &__button_backFinal {
            order: 2;
        }
    }
    .CustomFormSeparator {
        border-bottom: none;
    }
    .CustomFormSeparatorTop {
        border-top: none;
    }
    .Separator {
        display: none;
    }
}

@media screen and (max-width: $tablet) and (min-width: calc($mobile + 1px)) {
    .School {
        &__container {
            flex-direction: column;
        }
        &__header_account {
            display: flex;
            flex-direction: column;
        }
        &__header_accountBtn {
            margin-top: 0.5rem;
            margin-right: 0;
            align-items: center;
            justify-content: center;
        }
        &__logo {
            margin-bottom: 1rem;
        }
        &__title {
            font-size: calc(0.875rem + 0.6875 * ((100vw - 18.75rem) / 56.25));
        }

        &__banner_container {
            padding: calc(16% + 2rem) 1rem 9%;
        }
        &__banner_container_completion {
            padding: 8% 1rem 4%;
        }
        &__page_wrapper {
            padding: 0.5rem;
        }
        &__form_main {
            padding: 2rem;
        }
        &__form_formHeader,
        &__form_finalHeader,
        &__form_finalPara {
            font-size: calc(1.4rem + 0.6 * ((100vw - 18.75rem) / 56.25));
        }
        &__banner_container_header {
            font-size: calc(1.125rem + 0.25 * ((100vw - 18.75rem) / 56.25));
        }
        &__banner_container_message {
            font-size: calc(1.4rem + 0.6 * ((100vw - 18.75rem) / 56.25));
        }
        &__form_formHeader_completion {
            font-size: calc(1.35rem + 0.2125 * ((100vw - 18.75rem) / 56.25));
        }
        &__completion_messageHeader {
            font-size: calc(1.4rem + 0.6 * ((100vw - 18.75rem) / 56.25));
        }
    }

    .CustomBottom {
        &__buttonFinal {
            display: block;
        }
        &__button_back {
            margin-right: auto;
        }
    }
}

@media screen and (min-width: calc($tablet + 1px)) {
    .CustomBottom {
        &__buttonFinal {
            display: block;
        }
        &__button_back {
            margin-right: auto;
        }
    }
}

@media screen and (min-width: $laptop) and (max-width: calc($laptop + 1px)) {
    .School {
        &__header_accountBtn {
            margin-right: 18%;
        }
    }
}
