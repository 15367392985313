@import '../../../tenantConfig/styles/base.scss';

.ResponsiveLayout {
    display: flex;
    width: 100%;
    justify-content: center;

    &__content {
        width: $tablet;

        @media screen and (max-width: calc($tablet - 1px)) {
            width: 100%;
        }
    }
}
