@import '../../../tenantConfig/styles/base.scss';

.SingleCheck {
    display: flex;
    gap: 1rem;
    align-content: flex-start;

    &__label {
        font-family: $font-family;
        color: $dark;
    }

    &__icon {
        font-size: 0.9em;
        color: $white;
        max-width: 18px;
        max-height: 18px;
        min-width: 18px;
        min-height: 18px;
    }

    &__input,
    &__input_check {
        max-width: 18px;
        max-height: 18px;
        min-width: 18px;
        min-height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border: 2px solid $info-light;
        -webkit-transition: 400ms 100ms ease-out;
        -o-transition: 400ms 100ms ease-out;
        transition: 400ms 100ms ease-out;
    }

    &__check {
        border-radius: 0.2rem;
        margin-top: 0.25rem;
    }

    &__input {
        background-color: transparent;
    }

    &__input_check {
        background-color: $primary;
        outline: none;
        border: none;
    }

    &__input:focus,
    &__input_check:focus {
        outline: none;
        border: 2px solid $primary;
    }

    &__input:hover {
        border: 2px solid $primary;
    }

    &__indicatorContainer {
        max-width: 18px;
        max-height: 18px;
        min-width: 18px;
        min-height: 18px;
        display: flex;
        align-content: center;
        justify-content: center;
    }
}
