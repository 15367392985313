@import './base.scss';

.School {
    &__completion_downloadContainer {
        background: $secondary-darkest;
        margin-top: -2rem;
        padding: 2rem;
        color: $white;
    }
    &__completion_downloadHeader {
        font-family: $font-family-bold;
        font-size: 1.375em;
        margin: 0;
    }

    &__completion_download {
        margin-top: 1rem;
        transition: 0.2s ease-in-out;
        font-size: 1rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0.85em 1.25em;
        text-decoration: none;
        color: $white;
        font-family: $font-family-regular;
        text-transform: uppercase;
        border: 0;
        outline: 0;
        background: $primary;
        line-height: normal;
        position: relative;
        text-align: center;
        width: auto;
    }
    &__completion_downloadBtn {
        text-decoration: none;
        color: $white;
        font-family: $font-family-regular;
    }

    &__completion_container {
        border-top: 0.375rem solid $primary;
        background-image: $default-background-completion, url($account-bg-image);
        background-size: cover;
        background-position: 0% 50%;
        padding: 5rem 0;
        text-align: center;
    }
    &__completion_containerMessage {
        background: rgba(255, 255, 255, 0.7);
        margin: 0 auto;
        padding: 1.5rem;
        max-width: 37.5rem;
        box-shadow: none;
    }
    &__completion_messageHeader {
        font-weight: bolder;
        font-size: 2em;
        margin: 0 0 1rem 0;
        line-height: 1.2;
        font-family: $font-family-bold;
        color: $primary;
        p {
            color: $secondary-dark;
        }
    }
    &__completion_readStory {
        display: inline-flex;
        width: auto;
        display: inline-flex;
        width: auto;
        clip-path: polygon(95% 0, 100% 50%, 95% 100%, 0 100%, 0 0);
        transition: 0.2s ease-in-out;
        font-size: 1rem;

        justify-content: center;
        align-items: center;

        padding: 0.85em 1.25em;
        cursor: pointer;
        text-decoration: none;
        color: $white;
        font-family: $font-family-regular;
        text-transform: uppercase;
        border: 0;
        outline: 0;
        background: $primary;
        line-height: normal;
        position: relative;
        text-align: center;
        padding: 1.5rem;

        a {
            text-decoration: none;
            color: $white;
            font-family: $font-family-regular;
        }
    }

    @media screen and (max-width: $mobile) {
        &__completion_downloadContainer {
            padding: 1rem;
        }

        &__completion_downloadHeader {
            font-size: calc(1.125rem + 0.25 * ((100vw - 18.75rem) / 56.25));
        }
        &__completion_downloadBtn {
            font-size: 0.75rem;
        }
        &__completion_messageHeader {
            font-size: calc(1.4rem + 0.6 * ((100vw - 18.75rem) / 56.25));
        }
        &__completion_download {
            width: 84%;
        }
    }
}
