@import './base.scss';

.CustomModal {
    background-color: $white;
    position: fixed;
    opacity: 1;
    font-family: $font-family;
    color: $dark;
    padding: 2rem;
    border-radius: 0.2rem;
    box-shadow: $default-box-shadow;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow-y: scroll;

    &__header {
        font-family: $font-family-bold;
        text-transform: uppercase;
        font-size: 1.7rem;
        color: $primary;
        margin: 0;
    }

    &__body {
        font-family: $font-family;
        display: flex;
        flex-direction: column;
        align-self: center;
        padding: 1.5rem;
        width: 35%;
        border-radius: 0.2rem;
        max-height: 85%;
        background-color: $info-light;
        outline: none;
        overflow-y: scroll;
        scrollbar-width: none;
        /* Firefox */
        -ms-overflow-style: none;
        /* Internet Explorer 10+ */
    }

    &__body::-webkit-scrollbar {
        /* WebKit */
        width: 0;
        height: 0;
    }

    &__closeTrigger {
        background: $primary;
        border: none;
        position: absolute;
        top: 0rem;
        right: 0rem;
        transition: 0.2s ease-in-out;
        content: '';
        font-size: 1rem;
        line-height: 1;
        color: $primary;
        border-radius: 50%;
        z-index: 19999;
    }
    &__closeTrigger:hover {
        background-color: $secondary-darkest;
    }
    &__closeIcon {
        font-size: 2em;
        font-weight: bolder;
        color: $white;
    }

    &__overlay {
        position: fixed;
        justify-content: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
    }

    a {
        color: $primary;
    }
    a:hover {
        text-decoration: none;
    }

    @media screen and (max-width: $mobile) {
        &__closeTrigger {
            top: 2rem;
            right: 1rem;
        }
    }
}

.CustomModal::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
}

.ReactModal__Body--open {
    overflow-y: hidden;
}
