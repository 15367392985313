@import './base.scss';

.Form {
    &__CustomTextArea,
    &__CustomQuiestion {
        display: grid;
        grid-gap: 2rem;
        grid-template-columns: 3fr 5.5fr;
        justify-content: flex-start;
        padding: 1rem 0;
        font-family: $font-family;
    }
    &__CustomQuiestion_link {
        color: $primary;
    }
    &__CustomQuiestion_link:hover {
        text-decoration: none;
    }
    &__CustomTextArea_input {
        height: 8em;
    }
    &__CustomTextArea_description {
        display: none;
    }
    &__question {
        font-family: $font-family;
        align-self: center;
        font-size: 1em;
        color: $secondary-dark;
    }
    &__question_label {
        color: $secondary-dark;
    }
    &__question_following {
        align-self: auto;
    }
    &__CustomqaWrapper {
        display: block;
    }
    &__qaWrapper {
        display: grid;
        grid-gap: 2rem;
        grid-template-columns: 3fr 5.5fr;
        justify-content: flex-start;
        padding: 1rem 0;
    }
    &__gpa {
        margin-top: -0.5rem;
        font-style: italic;
        font-size: 0.75em;
        font-weight: 500;
    }
    &__lightBorder {
        border-bottom: 1px solid $secondary-light;
    }
    &__textAnswer,
    &__inputValue {
        width: calc(100% - 1.4rem);
        background-color: $white;
        border-radius: 0.375rem;
        line-height: 1.2;
        font-size: 1em;
        padding: 0.5rem;
        border: 1px solid $secondary-light;
    }

    &__textAnswer:focus,
    &__inputValue:focus {
        outline: none;
        border-color: $secondary;
        box-shadow: none;
    }

    &__subHeader {
        font-family: $font-family-bold;
        margin-bottom: 0.2rem;
    }
    &__help {
        font-size: 0.875em;
        font-style: italic;
        font-weight: 400;
        margin-top: 0rem;
    }

    @media screen and (max-width: $mobile) {
        &__CustomTextArea,
        &__CustomQuiestion,
        &__qaWrapper {
            display: block;
            padding: 0;
            margin: 1rem 0;
        }

        &__question {
            font-size: 0.938em;
            font-weight: 400;
            padding: 0 0 0.125em 0;
        }

        &__gpa {
            margin-top: -0.5rem;
            font-style: italic;
            font-size: 0.7em;
            font-weight: 500;
        }
    }
    @media screen and (max-width: $tablet) and (min-width: calc($mobile + 1px)) {
        &__CustomTextArea,
        &__CustomQuiestion,
        &__qaWrapper {
            padding: 0.4rem 0;
        }
        &__question {
            font-size: 1em;
        }
        &__gpa {
            margin-top: -0.5rem;
            font-style: italic;
            font-size: 0.75em;
            font-weight: 500;
        }
    }
}

.outlined {
    &__input:focus {
        background: $white;
    }
}

.FormAutoComplete {
    &__control:focus {
        background: $white !important;
    }
}

.Select {
    &__item {
        color: $secondary-dark !important;
    }
    &__item:hover,
    &__item:focus {
        background: $primary-dark !important;
        color: $white !important;
    }

    &__customSelectTrigger:focus,
    &__customSelectTrigger:hover {
        border: 1px solid $primary;
        background: $white;
    }

    &__customSelectTrigger {
        min-width: 8rem;
        background: $white;
        border-radius: 0rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $secondary-dark;
        border: 1px solid $secondary-light;
        outline: $primary;
        font-size: 0.95em;
        padding: 0.45rem 0.45rem;

        width: 100%;
    }
}
