@import '../../../tenantConfig/styles/base.scss';

.FormRadio {
    display: flex;
    align-items: center;

    &__label,
    &__radioLabel {
        font-family: $font-family;
    }

    &__label {
        padding: 0.4rem 0;
    }

    &__radioItem,
    &__radioContainer {
        display: flex;
        gap: 1rem;
        align-items: center;
    }

    &__input,
    &__input_checked {
        max-width: 18px;
        max-height: 18px;
        min-width: 18px;
        min-height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        border: 2px solid $info-light;
        -webkit-transition: 400ms 100ms ease-out;
        -o-transition: 400ms 100ms ease-out;
        transition: 400ms 100ms ease-out;
    }

    &__radio {
        border-radius: 100%;
    }

    &__input {
        background-color: transparent;
    }

    &__input_checked {
        background-color: $primary;
        outline: none;
        border: none;
    }

    &__input:focus,
    &__input_checked:focus {
        outline: none;
        border: 2px solid $primary;
    }

    &__input:hover {
        border: 2px solid $primary;
    }

    &__radioIndicator {
        max-width: 6px;
        max-height: 6px;
        min-width: 6px;
        min-height: 6px;
        background-color: $white;
        border-radius: 100%;
    }
}
