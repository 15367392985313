@import '../../tenantConfig/styles/base.scss';

.SharedAccount {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $secondary;
    min-height: 100vh;

    &__card {
        background-color: $white;
        font-family: $font-family;
        color: $dark;
        border-radius: 0.2rem;
        width: 50rem;
        height: 40rem;
        box-shadow: $heavy-box-shadow;
    }

    &__linkContainer {
        display: flex;
        justify-content: flex-start;
        font-size: 0.8rem;
    }

    &__linkWrapper {
        margin: 0;
    }

    &__link {
        margin: 0;
        color: $dark;
    }
}

.PasswordRequirements {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-bottom: 1rem;

    &__item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
    }

    &__icon_error,
    &__icon_success,
    &__icon_inactive {
        font-size: 1.1em;
    }

    &__icon_error,
    &__message_error {
        color: $error;
    }

    &__icon_success,
    &__message_success {
        color: $success;
    }

    &__icon_inactive,
    &__message_inactive {
        color: $muted;
    }

    &__message_error,
    &__message_success,
    &__message_inactive {
        margin: 0;
        font-family: $font-family;
        font-size: 0.9em;
    }
}

.SignUpCard,
.ForgotPassword,
.ResetPassword,
.ConfirmCard {
    &__inputContainer {
        padding-top: 2rem;
    }
}

.SignUpCard,
.LoginCard,
.ForgotPassword,
.ResetPassword,
.ConfirmCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $white;
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;

    &__input {
        width: 100%;
    }

    &__signUpLinks {
        display: flex;
        justify-content: flex-start;
    }

    &__inputContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;
    }

    &__passwordContainer {
        display: flex;
        flex-direction: column;
        gap: 0.2rem;
    }
}

.LoginCard {
    &__header {
        margin: 0;
    }

    &__passwordReset {
        margin: 0;
        color: $dark;
        display: flex;
        justify-content: flex-end;
        padding-top: 0.5rem;
        font-size: 0.8rem;
    }

    &__resendConfirmCodeButton {
        padding: 0;
        padding-top: 0.5rem;
        font-family: $font-family;
        display: flex;
        justify-content: flex-end;
        background: none;
        border: none;
        color: $dark;
        text-decoration: underline;
        font-size: 0.8rem;
    }
}

.ConfirmCard {
    &__resendConfirmCodeButton {
        padding: 0;
        font-family: $font-family;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        background: none;
        border: none;
        color: $dark;
        text-decoration: underline;
        font-size: 0.8rem;
    }
}
