@import '../../../tenantConfig/styles/base.scss';

// NOTE: in this file, !important is often required to overwrite the styles from the react-select's base component
.FormAutoComplete {
    &__control {
        padding: 0.25rem 0rem;
        min-height: 18px !important;
        font-family: $font-family;
        border: 1px solid $light !important;
        border-radius: 0.2rem !important;

        &--is-focused {
            border: 1px solid $primary !important;
            box-shadow: none !important;
        }
    }

    &__input {
        padding: 0;
        margin: 0;
        &-container {
            margin: 0 !important;
            padding: 0 !important;
        }
    }

    &__option {
        color: $secondary;
        padding: 0.25rem 0.5rem !important;

        &--is-focused {
            background-color: $info-light !important;
        }
    }

    &__menu {
        font-family: $font-family;
    }

    &__menu-list {
        font-family: $font-family;
        border-radius: 0.2rem !important;
    }

    &__clear-indicator,
    &__dropdown-indicator {
        padding: 0 0.2rem !important;
    }

    &__indicators {
        height: fit-content;
    }

    &__indicator-separator {
        height: 10px;
    }
    &__subtext {
        text-transform: uppercase;
        font-size: 0.6rem;
        font-family: $font-family-bold;
        letter-spacing: 1pt;
    }
}
