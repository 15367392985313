@import '../../../tenantConfig/styles/base.scss';

.Select {
    &__selectTrigger {
        min-width: 8rem;
        background-color: transparent;
        border-radius: 0.2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $secondary;
        border: 1px solid $light;
        outline: $primary;
        font-size: 0.95em;
        padding: 0 0.5rem;
        font-family: $font-family;
        width: 100%;
    }

    &__selectTrigger:focus {
        border: 1px solid $primary;
    }

    &__item {
        padding: 0.25rem 1rem;
        color: $secondary;
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        align-items: baseline;
        line-height: 1.5;
        user-select: none;
    }

    &__item:hover,
    &__item:focus {
        background-color: $info-light;
        outline: none;
        box-shadow: none;
    }

    &__optionsViewport {
        all: unset;
        font-family: $font-family;
        background-color: $white;
        border-radius: 0.2rem;
        box-shadow: $default-box-shadow;
        padding: 0.5rem 0;
    }

    &__label {
        font-family: $font-family;
        color: $secondary;
        margin: 0;
    }

    &__checkIcon {
        font-size: 0.9em;
        padding: 0.5rem;
    }

    &__iconContainer {
        border-radius: 2rem;
        background-color: $light;
    }

    &__triggerIconContainer {
        padding-top: 0.25rem;
        font-size: 0.9em;
    }
}

.SelectSingle,
.SelectGroup {
    &__label {
        font-family: $font-family;
        color: $secondary;
        margin: 0;
    }
}

.SelectGroup {
    &__answers {
        display: flex;
        gap: 1rem;
    }
}
