@import '../../../tenantConfig/styles/base.scss';

.underlined {
    &__input {
        border: none;
        outline: none;
        border-bottom: 1px solid $light;
    }
}

.underlined::after {
    content: '';
    display: block;
    margin: auto;
    height: 2px;
    width: 0px;
    background-color: $light;
    transition: width 0.5s ease, background-color 0.5s ease;
}

.underlined:hover::after,
.underlined:focus-within::after {
    width: 100%;
    background: $primary;
}

.outlined {
    &__input {
        border: 1px solid $light;
        transition: border 0.5s ease;
        border-radius: 0.2rem;
    }

    &__input:focus,
    &__input:hover {
        border: 1px solid $primary;
    }
}

.underlined,
.outlined {
    width: 100%;

    &__input {
        outline: none;
        width: 100%;
        color: $secondary;
        font-size: 1em;
        font-family: $font-family;
        padding: 0.25rem 0.5rem;
        line-height: 1.5;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }

    &__input:focus,
    &__input:hover {
        outline: none;
    }
}
